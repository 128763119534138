import React, {HTMLProps, useState} from "react";

import {useChatScrollContext} from "../../../../../context/chat-contexts/chat-scroll-context";
import ImageEditorModal from "../../../../../modals/image-editor";

import styles from "./link-reader.module.scss";

const getServiceInfo = (url: string) => {
	if (url.includes("/sd/")) return {name: "Stable Diffusion", color: "#a69cfe"};
	if (url.includes("/dalle/")) return {name: "Dalle", color: "#D0F8AB"};
	if (url.includes("/google/")) return {name: "Imagen", color: "#A5F0FC"};
	return {name: "User Upload", color: "#a69cfe"};
};

const LinkRenderer = ({href, children}: HTMLProps<HTMLAnchorElement>) => {
	const {scrollListToBottom} = useChatScrollContext();
	const [isShowing, setIsShowing] = useState(false);
	const {name, color} = getServiceInfo(href ?? "");

	const isImage = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif|webp))/i;
		return imageUrlRegex.test(content);
	};

  const handleClick = (href) => {
    //setIsShowing(true);
    window.open(href, "_blank");   
  };

  return (
    <>
      {isImage(href ?? "") ? (
        <>
          <ImageEditorModal src={href} isShowing={isShowing} setIsShowing={setIsShowing} />
          <div className={styles.imageContainer}>
            <div className={styles.imageLabelContainer} style={{backgroundColor: color}}>
              {name}
            </div>
            <img
              className={styles.amazonImg}
              src={href}
              onClick={() => handleClick(href)}
              alt={children?.[0] as string}
              onLoad={() => scrollListToBottom()}
            />
          </div>
        </>
      ) : (
        <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
          {children}
        </a>
      )}
    </>
  );
};

export default LinkRenderer;
