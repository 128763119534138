import {markResult, PatternProps, ReplacerFunc, splitTaggedResultIntoChunks} from ".";

export interface MentionPatternProps extends PatternProps {
  mentions: string[];
}

const escapeSpecialCharacters = (str: string): string => {
	// eslint-disable-next-line no-useless-escape
	return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export const mentionPatternReplacer: ReplacerFunc<MentionPatternProps> = (props) => {
  try {
    const {text, render, mentions} = props;

    const result = mentions.reduce((acc, mention) => {
      console.log("mention: ", mention);
      try {
        const regex = new RegExp(`(?<=\\s|^)@${escapeSpecialCharacters(mention)}(?=\\s|$)`, "g");
        const result = acc.replace(regex, markResult(`@${mention}`));
        return result;
      } catch (error) {
        console.error(`Error in mentionPatternReplacer: word with issue -> ${mention}`, error);
        return "";
      }

      return result;
    }, text);

    return splitTaggedResultIntoChunks(result, render);
  } catch (error) {
    console.error("Error in mentionPatternReplacer", error);
    return [];
  }
};
