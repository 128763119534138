import {CREATE_ATTRIBUTE, UPDATE_ATTRIBUTE} from "../../graphql/mutations/attribute-mutations";
import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {GET_ATTR_CATEGORIES, GET_ATTR_DETAILS} from "../../graphql/queries/attribute-queries";
import {Icon, Select} from "../../shared";
import {AttributeCategoriesData, AttributeData, AttributeType} from "../../models/attribute";
import {Link} from "../../route";
import {gql, useMutation, useQuery} from "@apollo/client";
import {ToastContext} from "../../context/toast-context";
import styles from "./attribute-modal.module.scss";
import {updateCacheAddPageItem} from "../../shared/utility/update-cache";
import {useWorkspaceContext} from "../../context/workspace-context";
import {Button, Input, Subheader, TextArea, Modal, BaseModalProps} from "../../shared/v2";
import {groupBy} from "lodash-es";
import {QUESTION_TYPES} from "../../shared/constants/constants";
import {Question, determineQuestionType} from "../../models/questions";

export interface AttributeModalProps extends BaseModalProps{
	id?: string;
}

const AttributeModal = ({id, isOpen, onClose}: AttributeModalProps): ReactElement => {
	const [name, setName] = useState<string | undefined>("");
	const [description, setDescription] = useState<string | undefined>("");
	const [categoryId, setCategoryId] = useState<string | undefined>();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const {updateToast} = useContext(ToastContext);
	const {data} = useQuery<AttributeData>(GET_ATTR_DETAILS, {
		skip: !id,
		variables: {id},
		fetchPolicy: "no-cache",
	});
	const {data: attrCatData} = useQuery<AttributeCategoriesData>(
		GET_ATTR_CATEGORIES,
		{variables: {workspaceId}},
	);

	const categoriesSelectData = attrCatData?.attributeCategories.items.map(
		({name: text, id: value}) => ({text, value}),
	) ?? [];

	const closeModal = (): void => {
		setName("");
		setDescription("");
		setCategoryId(undefined);
		onClose();
	};

	const [createAttribute] = useMutation(CREATE_ATTRIBUTE, {
		onCompleted: () =>{
			updateToast({description: "Created new property", type: "informational"});
			closeModal();
		},
	});
	const [updateAttribute] = useMutation(UPDATE_ATTRIBUTE, {
		onCompleted: () => {
			updateToast({description: "Updated property", type: "informational"});
			closeModal();
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCategorySelect = (selectedValue): any => {
		setCategoryId(selectedValue);
	};

	useEffect(() => {
		if (!categoryId && isOpen) {
			setCategoryId(
				attrCatData?.attributeCategories.items.find(({isDefault}) => isDefault)?.id,
			);
		}
	}, [attrCatData, isOpen]);

	useEffect(() => {
		if (data) {
			const {name: attrName, category: attrCategory, description: attrDescription} = data.attribute;

			setName(attrName);
			setDescription(attrDescription);
			setCategoryId(attrCategory?.id);
		}
	}, [data]);

	const handleSave = (): void => {
		if (id) {
			updateAttribute({
				variables: {id, changes: {name, description, categoryId: categoryId ?? null}},
			});
		} else {
			createAttribute({
				variables: {workspaceId, name, description, categoryId, type: AttributeType.USER},

				update(cache, {data: createData}) {
					if (!createData) return;
					const ref = cache.writeFragment({
						data: createData.createAttribute,
						fragment: gql`
							fragment NewAttribute on Attribute {
								id
								workspaceId
								name
								type
								description
								rules {
									id
									attributeId
									questionId
									picked
									action
								}
							}`,
					});
					updateCacheAddPageItem(
						cache,
						ref,
						"attributes",
						createData.createAttribute.id,
					);
				},
			});
		}
	};

	const renderLinks = useCallback(() => {
		if (!data) return undefined;
		const groupedById = groupBy(data?.attribute.rules, "question.surveyId");
		const stuff = Object.keys(groupedById).map(key => {
			// Each question here will have the same survey as its parent
			const surveyName = groupedById[key][0].question?.survey.name;
			const questionLinks = groupedById[key].map(rule => <Link key={rule.questionId}
				to={`/survey/${rule.question?.surveyId}/results/${rule.question?.id}`}
				className={styles.link}
			>
				<Icon
					className={styles.questionIcon}
					name={QUESTION_TYPES[determineQuestionType(rule.question as Question)].icon}
				/>
				{rule.question?.text}
			</Link>);
			return (
				<div key={groupedById[key][0].id}>
					<Link to={`/survey/${groupedById[key][0].question?.surveyId}/questions`}>📊{surveyName}</Link>
					<div className={styles.indented}>{questionLinks}</div>
				</div>
			);
		});
		return stuff;
	}, [data]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			title={id ? "Property Details" : "New Property"}
			type="sidebar"
		>
			<div className={styles.content}>
				<div className={styles.inputs}>
					<Input id="property-name" onChange={setName} value={name || ""} label="Name"/>
					<Select
						id="published-status"
						size="small"
						label="Category"
						inputClass={styles.category}
						selectedValue={categoryId}
						onChange={handleCategorySelect}
						options={categoriesSelectData}
					/>
					<TextArea
						label="Description (optional)"
						className={styles.description}
						onChange={setDescription}
						value={description || ""}
					/>
					{
						data && data.attribute.rules.length > 0 &&
						<div className={styles.usage}>
							<Subheader>Used {data.attribute.rules.length} times in this workspace</Subheader>
							{renderLinks()}
						</div>
					}
				</div>
				<div className={styles.actions}>
					<Button variant="outlined" onClick={closeModal}>Cancel</Button>
					<Button onClick={handleSave}>Save</Button>
				</div>
			</div>
		</Modal>
	);
};

export {AttributeModal};
