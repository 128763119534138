import {
	ADD_REEL,
	PUBLISH_REEL,
	SHARE_REEL_WITH_BRAND,
} from "../../../../../graphql/mutations/reel-mutations";
import {
	GET_ALL_ANSWERS,
	GET_FEEDBACK_QUESTIONS,
	GET_FEEDBACK_SURVEY,
} from "../../../../../graphql/queries/survey-queries";
import {AddReelModal} from "../../../../../modals/add-reel";
import {AddReelVars, CreatedReel} from "../../../../../models/reels";
import {AnswerPageData} from "../../../../../models/answer";
import {Body, Subheader} from "../../../../../shared/v2/typography";
import {Checkbox} from "../../../../../shared/v2";
import {CREATE_CLIPS} from "../../../../../graphql/mutations/clip-mutations";
import {CreateClipsReturn, CreateClipsVars} from "../../../../../models/clip";
import {CssSpinner} from "../../../../../shared";
import {FeedbackQuestion, FeedbackQuestionsPageReturn} from "../../../../../models/questions";
import {HighlightContextProvider} from "../../../../../context/highlight-context";
import {REVIEW_ANSWER} from "../../../../../graphql/mutations/survey-mutations";
import {ReviewCard} from "../../components/review-card";
import {ReviewModal} from "../../modals/review-modal";
import {ToastContext} from "../../../../../context/toast-context";
import {TranscriptModalContextProvider} from "../../../../../context/transcript-modal-context";
import {useLoadingQuery} from "../../../../../hooks";
import {NetworkStatus, useMutation} from "@apollo/client";
import {useWorkspaceContext} from "../../../../../context/workspace-context";
import React, {ReactElement, useContext, useState} from "react";
import styles from "./reviews.module.scss";

import classNames from "classnames/bind";
import {useMount} from "../../../../../hooks/useMount";

const bStyles = classNames.bind(styles);

const ReviewsPage = (): ReactElement => {
	const {workspace: {brand, id}} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const [isShowingReelModal, setIsShowingReelModal] = useState(false);
	const [singleAnswerId, setSingleAnswerId] = useState({answerId: "", description: ""});
	const [selectedId, setSelectedId] = useState("");
	const [unreviewed, setUnreviewed] = useState<boolean | undefined>(undefined);
	const [isCreating, setIsCreating] = useState(false);
	const [activeQuestion, setActiveQuestion] = useState<FeedbackQuestion | undefined>();

	const {data, fragment: survFrag} = useLoadingQuery(GET_FEEDBACK_SURVEY, {
		skip: !brand?.id,
		variables: {brandId: brand?.id},
		what: "reviews",
	});

	const {data: questionData, fragment: quesFrag} =
	useLoadingQuery<FeedbackQuestionsPageReturn>(GET_FEEDBACK_QUESTIONS, {
		skip: !data || !brand?.id || !data.feedbackSurvey,
		variables: {id: data?.feedbackSurvey?.id},
		what: "reviews",
		onCompleted: ques => {
			setActiveQuestion(ques.questions.items[0]);
		},
	});

	const {
		data: answers,
		networkStatus,
		fetchMoreFragment,
		handleFetchMore,
	} = useLoadingQuery<AnswerPageData>(GET_ALL_ANSWERS, {
		skip: !activeQuestion,
		notifyOnNetworkStatusChange: true,
		errorPolicy: "all",
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		variables: {
			questionId: activeQuestion?.id,
			limit: 20,
			sort: "MOST_LIKES",
			filter: {reviewed: unreviewed},
		},
	});

	const manualFetchMore = (): void => {
		handleFetchMore(20);
	};

	const [createReel] = useMutation<CreatedReel, AddReelVars>(ADD_REEL);
	const [reviewed] = useMutation(REVIEW_ANSWER);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS);
	const [shareReel] = useMutation(SHARE_REEL_WITH_BRAND, {
		onCompleted: () => {
			setIsCreating(false); // Done "creating" a reel after this point.
			setIsShowingReelModal(false);
			updateToast({description: "Publishing Reel to brand page", type: "informational"});
		},
	});
	const [publishReel] = useMutation(PUBLISH_REEL);

	useMount(() => {document.title = "Vurvey - Mentions"})


	const handleCreateClip = (reelId: string): void => {
		createClips({
			variables: {reelId,
				input: {answerId: singleAnswerId.answerId}},
			onCompleted: () => {
				shareReel({variables: {reelId, share: true}});
				publishReel({variables: {id: reelId}});
				setSingleAnswerId({answerId: "", description: ""});
			},
			onError: () => setIsCreating(false),
		});
	};

	const handleReviewed = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.checked) return setUnreviewed(false);
		setUnreviewed(undefined);
	};

	const handleCreateAndSave = (name: string, description?: string): void => {
		setIsCreating(true);
		createReel({
			variables: {input: {name, workspaceId: id, description}},
			onCompleted: d => {
				if (!d.createReel) return;
				reviewed({variables: {id: singleAnswerId.answerId}});
				handleCreateClip(d.createReel.id);
			},
			onError: () => {
				updateToast({description: "An error occurred, try again later", type: "failure"});
				setIsCreating(false);
			},
		});
	};

	const handleOpenCreateReel = (answerId: string, description?: string): void => {
		setSingleAnswerId({answerId, description: description || ""});
		setIsShowingReelModal(true);
	};

	const closeAdd = (): void => {
		setIsShowingReelModal(false);
		setSingleAnswerId({answerId: "", description: ""});
	};

	const toggleTranscript = (): void => setSelectedId("");
	return (
		<div>
			<TranscriptModalContextProvider answerId={selectedId} setAnswerId={setSelectedId}>
				<HighlightContextProvider>
					{questionData && <div className={styles.questions}>{questionData.questions.items.map(
						question => <span
							key={question.id}
							onClick={() => setActiveQuestion(question)}
						>
							<Body className={bStyles(
								"questionLink",
								{active: activeQuestion?.id === question.id},
							)}
							>
								{question.text}
							</Body>
						</span>,
					)}</div>}
					{survFrag || quesFrag || (activeQuestion && activeQuestion.answerCount ? (<>
						<Subheader className={styles.header} size="l" type="medium">
							{activeQuestion.text}
						</Subheader>
						<Checkbox
							checked={unreviewed === false}
							id="not-reviewed-only"
							onChange={handleReviewed}
							text={(
								<Body size="s" color="text-secondary" type="medium">
										Unreviewed Only
								</Body>
							)}
							className={styles.checkbox}
						/>
						<div className={styles.answers}>
							{networkStatus === NetworkStatus.setVariables ?
								<CssSpinner className={styles.spinner}/> :
								answers?.answers.items.length === 0 ? <div>You have reviewed all responses</div> :
									answers?.answers.items.map(answer =>
										<ReviewCard key={answer.id} answer={answer} handleOpen={handleOpenCreateReel}/>)
							}
						</div>
						{answers && answers.answers.remaining > 0 && !fetchMoreFragment &&
								<div className={styles.fetch}>
									<span onClick={manualFetchMore}>
										Load more ({answers?.answers.remaining} remaining)
									</span>
								</div>
						}
						{fetchMoreFragment}
					</>
					) : <div className={styles.noResponses}>
						<img src="images/no-responses.png"/>
						<Body className={styles.none} size="xl">There are no reviews yet</Body>
					</div>)}
					<ReviewModal
						answerId={selectedId}
						onClose={toggleTranscript}
						openReelModal={handleOpenCreateReel}
					/>
					<AddReelModal
						isOpen={isShowingReelModal}
						onClose={closeAdd}
						workspaceId={id}
						handleSave={handleCreateAndSave}
						defaultName={`${brand?.name} review`}
						defaultDesc={singleAnswerId.description}
						isCreating={isCreating}
					/>
				</HighlightContextProvider>
			</TranscriptModalContextProvider>
		</div>
	);
};

export {ReviewsPage};
