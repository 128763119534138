import React, {ReactElement, useMemo} from "react";
import {Outlet, useLocation} from "react-router";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {HandOutputStarIcon, ModelsIcon, SparkAiStarsIcon} from "../../../icons";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";

export const WorkflowPage = (): ReactElement => {
	const location = useLocation();

	const displaySubNavigation = useMemo(() => {
		return !location.pathname.includes("/workflow/conversation")
	}, [location.pathname])
	return <NavigationPageLayout>
		{displaySubNavigation &&
			<PageSubNavigation header="Workflow">
				<ButtonGroup>
					<NavigationTooltip
						text="Check your conversations"
					>
						<ButtonGroupElement
							icon={<SparkAiStarsIcon />}
							text="Conversations"
							to=""
							end
						/>
					</NavigationTooltip>
					<NavigationTooltip
						text="Create workflows to automate the creation of new outputs"
					>
						<ButtonGroupElement
							icon={<ModelsIcon />}
							text="Flows"
							to="flows"
						/>
					</NavigationTooltip>
					{/* <NavigationTooltip
						text="See the results of your workflows"
					>
						<ButtonGroupElement
							icon={<HandOutputStarIcon />}
							text="Outputs"
							to="outputs"
						/>
					</NavigationTooltip> */}
				</ButtonGroup>
			</PageSubNavigation>
		}

		<Outlet />
	</NavigationPageLayout>
}
