import React, {ReactElement, useState} from "react"

import {WorkflowProvider, WorkflowEventsProvider, CarouselScrollProvider} from "../../../context/workflow-contexts";
import {TopBar} from "../../components/topbar";
import {WorkflowCanvas} from "../../components/workflow-canvas";
import {WorkflowModals} from "../../components/workflow-modals";

import styles from "./flow-page.module.scss"
import {RunWorkflowContextProvider} from "../../../context/workflow-contexts/run-workflow-context";

export const FlowPage = (): ReactElement => {
	const [isEditWorkflowModalOpen, setIsEditWorkflowModalOpen] = useState(false);
	const [isSelectAgentModalOpen, setIsSelectAgentModalOpen] = useState(false);
	const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);

	return (
		<CarouselScrollProvider>
			<WorkflowProvider>
				<WorkflowEventsProvider>
					<RunWorkflowContextProvider>
						<div className={styles.layout}>
							<TopBar setIsEditWorkflowModalOpen={setIsEditWorkflowModalOpen} />
							<WorkflowCanvas
								setIsSelectAgentModalOpen={setIsSelectAgentModalOpen}
								setIsSourcesModalOpen={setIsSourcesModalOpen}
							/>
						</div>
						<WorkflowModals
							isEditWorkflowModalOpen={isEditWorkflowModalOpen}
							isSelectAgentModalOpen={isSelectAgentModalOpen}
							isSourcesModalOpen={isSourcesModalOpen}
							setIsEditWorkflowModalOpen={setIsEditWorkflowModalOpen}
							setIsSelectAgentModalOpen={setIsSelectAgentModalOpen}
							setIsSourcesModalOpen={setIsSourcesModalOpen}

						/>
					</RunWorkflowContextProvider>
				</WorkflowEventsProvider>
			</WorkflowProvider>
		</CarouselScrollProvider >
	);
}
